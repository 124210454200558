import { Grid } from '@enterprise-ui/canvas-ui-react'
import questionMark from '../utils/images/questionMark.png'
const TempErrorModal = () => {
  return (
    <Grid.Container align="center">
      <Grid.Item xs={3}>
        <img
          src={questionMark}
          alt="Question mark icon indicating more information needed"
          width="80%"
        />
      </Grid.Item>
      <Grid.Item xs={7}>
        <p className="modalText">
          your QR code was not generated successfully <br />
          <br />
          please wait a few moments and try again <br />
          <br />
          if the problem persists, visit a security desk for a plastic badge or
          further assistance
        </p>
      </Grid.Item>
    </Grid.Container>
  )
}

export default TempErrorModal
