import Axios from 'axios'

export const createDailyIssuanceBadge = async (
  APIurl,
  apiKey,
  lanId,
  currentDate,
  isTruCredRequest,
) => {
  // TODO: add specific key for visitorPortal
  const endpoint = `${APIurl}temp_badges?key=${apiKey}&currentDate=${currentDate}&is_trucred_request=${isTruCredRequest}`
  try {
    const response = await Axios.post(endpoint, {
      lan_id: lanId,
      currentDate,
      isTruCredRequest,
    })
    return response?.data
  } catch (e) {
    return {
      error: true,
    }
  }
}
