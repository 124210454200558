import { createDailyIssuanceBadge } from '../api/createDailyIssuanceBadge.js'
import apiConfig from '../apiConfig.js'
import { useState } from 'react'
import { addHours, format } from 'date-fns'
import { DefaultErrorMessage } from '../utils/error-util.js'

export default function useCreateDailyIssuanceBadge(session) {
  const [badgeNumber, setBadgeNumber] = useState()
  const [isLoadingDailyIssuance, setIsLoadingDailyIssuance] = useState(false)
  const [dailyIssuanceError, setDailyIssuanceError] = useState('')

  /* formats time and date to MM/DD/YYYY h:mmAM/PM */
  const dailyIssuanceBadgeExpirationTime = format(
    new Date(addHours(new Date(), 10)),
    'MM/dd/yyyy h:mma',
  )
  const currentDate = format(new Date(), 'MM-dd-yyyy hh:mm a')

  const generateBadge = async () => {
    setIsLoadingDailyIssuance(true)
    const temp = await createDailyIssuanceBadge(
      apiConfig.apiUrl,
      apiConfig.apiKey,
      session?.userInfo?.lanId,
      currentDate,
      true,
    )
    setIsLoadingDailyIssuance(false)
    if (temp?.error) {
      setDailyIssuanceError(DefaultErrorMessage)
      return null
    }
    setBadgeNumber(temp?.data?.qrCode)
  }
  return {
    generateBadge,
    badgeNumber,
    isLoadingDailyIssuance,
    dailyIssuanceBadgeExpirationTime,
    dailyIssuanceError,
    setDailyIssuanceError,
  }
}
